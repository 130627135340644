import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';
import { Alignment } from '@design/table/table';

@Component({
  selector: 'td[ccTd]',
  templateUrl: './td.component.html',
  styleUrls: ['./td.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TdComponent {
  @Input('ccHorizontalAlignment')
  horizontalAlignment: Alignment = 'start';

  @Input('ccVerticalAlignment')
  verticalAlignment: Alignment | 'baseline' = 'center';

  get tdClass(): string {
    return `td-wrapper--horizontal-align-${this.horizontalAlignment}`;
  }

  @HostBinding('style.vertical-align')
  get verticalAlign(): string {
    switch (this.verticalAlignment) {
      case 'start':
        return 'top';
      case 'end':
        return 'bottom';
      case 'center':
        return 'middle';
      case 'baseline':
        return 'baseline';
    }
  }
}
