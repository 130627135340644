<div
  [class.cc-table--minimal]="style === 'minimal'"
  [class.cc-table--sticky-header]="stickyHeader"
  class="cc-table"
>
  <div class="cc-table__header">
    @if (!showBulkActions) {
      <div class="cc-table__header-title">
        <ng-content select="[slot='cc-table-title']"></ng-content>
      </div>
    }

    @if (showBulkActions) {
      <div class="cc-table__header-bulk-actions">
        <div class="cc-table__header-bulk-actions-count">
          <ng-content select="[slot='cc-table-bulk-actions-count']"></ng-content>
        </div>
        <div class="cc-table__header-bulk-actions-divider"></div>
        <ng-content select="[slot='cc-table-bulk-actions']"></ng-content>
      </div>
    }

    <div class="cc-table__header-actions">
      @if (searchable) {
        @if (isSearchEnabled || searchControl.value) {
          <app-textbox
            #searchBox
            (clearIconClick)="disableSearch()"
            [formControl]="searchControl"
            [placeholder]="searchPlaceholder"
            [showClearIcon]="true"
            icon="icon-search"
            width="200px"
          />
        } @else {
          <app-button
            (click)="isSearchEnabled = true"
            [size]="ButtonSize.Big"
            [type]="ButtonType.Ghost"
            [ccTooltip]="'table.search' | translate"
          >
            <i
              class="icon-search"
              slot="ghost-icon"
            ></i>
          </app-button>
        }
      }

      <ng-content select="[slot='сс-table-custom-actions']"></ng-content>
    </div>
  </div>

  <div class="cc-table__custom-filters">
    <ng-content select="[slot='cc-table-custom-filters']"></ng-content>
  </div>

  <div class="cc-table__table">
    <table
      [class]="'cc-table__table-native--padding-' + padding"
      class="cc-table__table-native"
    >
      <ng-content select="[slot='cc-table']"></ng-content>
    </table>

    <div class="cc-table__table-empty-row">
      @if (emptyRowTemplate) {
        <ng-container [ngTemplateOutlet]="emptyRowTemplate"></ng-container>
      } @else {
        {{ 'table.defaultPlaceholder' | translate }}
      }
    </div>
  </div>
</div>
