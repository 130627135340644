import { ChangeDetectionStrategy, Component, HostBinding, Input, OnDestroy, type OnInit, output } from '@angular/core';
import { ButtonSize, ButtonType } from '@design/buttons/button/types';
import { UntilDestroy } from '@ngneat/until-destroy';
import { asyncScheduler, Subscription } from 'rxjs';
import { toastAnimation } from '@design/overlays/toast/animation';
import { ToastType } from '@design/overlays/toast/toast';
import { ButtonComponent } from '@design/buttons/button/button.component';
import { TranslateModule } from '@ngx-translate/core';
import { TooltipDirective } from '@design/overlays/tooltip/tooltip.directive';

@UntilDestroy()
@Component({
  selector: 'cc-toast',
  templateUrl: './toast.component.html',
  styleUrls: ['./toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [toastAnimation],
  standalone: true,
  imports: [ButtonComponent, TranslateModule, TooltipDirective],
})
export class ToastComponent implements OnInit, OnDestroy {
  @Input()
  message: string;

  @Input()
  hideAfterMs = 0;

  @Input()
  type: ToastType = ToastType.Info;

  close = output<void>();

  protected readonly ButtonType = ButtonType;
  protected readonly ButtonSize = ButtonSize;
  protected readonly ToastType = ToastType;

  private autoHideScheduler: Subscription | undefined;

  @HostBinding('@toastAnimation')
  get toastAnimation(): boolean {
    return true;
  }

  @HostBinding('class.info')
  get info(): boolean {
    return this.type === ToastType.Info;
  }

  @HostBinding('class.error')
  get error(): boolean {
    return this.type === ToastType.Error;
  }

  @HostBinding('class.warning')
  get warning(): boolean {
    return this.type === ToastType.Warning;
  }

  @HostBinding('class.success')
  get success(): boolean {
    return this.type === ToastType.Success;
  }

  ngOnInit(): void {
    if (this.hideAfterMs !== 0)
      this.autoHideScheduler = asyncScheduler.schedule(() => this.close.emit(), this.hideAfterMs);
  }

  ngOnDestroy(): void {
    this.autoHideScheduler?.unsubscribe();
  }
}
